import React, { Component } from 'react'

import OrdersService from '../../services/OrdersService'
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

import DateTimeUtils from '../../utils/DateTimeUtils'
import AuthService from '../../services/AuthService';

export default class Orders extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };
  }

  async componentDidMount() {
    if(!AuthService.isLoggedIn()) {
      window.location.href = '/';
    }
    var data = await OrdersService.getOrders();
    this.setState({ orders: data.orders });
  }

  formatStatus(status) {
    if (status == 0) {
      return "Ausstehend";
    } else if (status == 1) {
      return "In Bearbeitung"
    } else if (status == 3) {
      return "Fertig";
    } else if (status == 5) {
      return "Storniert";
    }
    return null;

  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col sm={6}>
              <h3 className='pt-4'>
                {AuthService.isAdmin() ? "Alle Aufträge" : "Meine Aufträge"}
              </h3>
            </Col>
            <Col sm={6}>
              <div className='pt-4 d-flex justify-content-end'>
                <Button onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/orders/addOrder';
                }} className='bg-success'><FaPlus /></Button>
              </div>
            </Col>
          </Row>

          {/* OrdersTable */}
          <Table responsive variant='dark' striped bordered hover className='mt-2'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Typ</th>
                <th>Datum</th>
                <th>Status</th>
                {AuthService.isAdmin() ? (
                  <td>Erstellt von</td>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {this.state.orders.map((item, index) => {
                return (
                  <tr key={index} onClick={e => {
                    e.preventDefault();
                    window.location.href = '/orders/' + item.id;
                  }}>
                    <td>{item.id}</td>
                    <td>{item.docType == 0 ? "KV" : "RE"}</td>
                    <td>{DateTimeUtils.formatDateTime(item.orderDate)}</td>
                    <td>{this.formatStatus(item.status)}</td>
                    {AuthService.isAdmin() ? (
                      <td>{item.createdBy}</td>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Container >
      </>
    )
  }
}
