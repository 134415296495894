import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Products from '../../../services/Products'

export default class MaterialAddModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      selectedMaterial: null
    }
  }

  async componentDidMount() {
    var materials = await Products.getMaterials();
    this.setState({ materials: materials });
  }
  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Material Hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form method='POST' onSubmit={(e) => {
            e.preventDefault();
            this.props.addToCart(this.state.selectedMaterial);
          }}>
            <Form.Group>
              <Form.Select className='bg-dark text-white'
                value={this.state.selectedMaterial == null ? '' : this.state.selectedMaterial.id}
                onChange={(e) => {
                  e.preventDefault();
                  var findMaterial = this.state.materials.find(o => o.id == e.target.value);
                  findMaterial.quantity = 0;
                  this.state.selectedMaterial = findMaterial;
                  this.forceUpdate();
                }}>
                <option value="" disabled>---</option>
                {this.state.materials.map((item, i) => {
                  return (
                    <option key={i} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
            </Form.Group>
            <Row className='pt-3 pb-3'>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Menge pro Einheit</Form.Label>
                  <Form.Control disabled={
                    this.state.selectedMaterial == null ? true : false
                  } type='text' name='quantity' onChange={(e) => {
                    e.preventDefault();
                    this.state.selectedMaterial.quantity = e.target.value;
                    this.forceUpdate();
                  }} value={this.state.selectedMaterial == null ? 0 :
                    this.state.selectedMaterial.quantity} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Einheit</Form.Label>
                  <br />
                  <Form.Label>
                    {this.state.selectedMaterial == null ? "" :
                      this.state.selectedMaterial.unit.name}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Preis pro Einheit</Form.Label>
                  <br />
                  <Form.Label>
                    {this.state.selectedMaterial == null ? "" : 
                    this.state.selectedMaterial.unitPrice + " €"}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Gesamt</Form.Label>
              <br />
              <Form.Label>{this.state.selectedMaterial == null ? 0.00 :
                (this.state.selectedMaterial.unitPrice * this.state.selectedMaterial.quantity)} €</Form.Label>
            </Form.Group>
            <Form.Group>
              <Button variant='success' type='submit'>
                Speichern
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </>
    )
  }
}
