class AuthService {


    async login(email, password) {
        try {
            var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/auth/login', {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });
            var data = await response.json();
            if (data.failed == false) {
                this.setToken(data.token);
                return true;
            } else {
                return false;
            }
        } catch (err) {

        }
    }

    getToken() {
        try {
            if (localStorage.getItem('token') == null) return false;
            return localStorage.getItem('token');
        } catch (err) {
            return null;
        }
    }

    decodeToken() {
        try {
            return JSON.parse(atob(this.getToken().split(".")[1]));
        } catch (err) {
            return null;
        }

    }

    setToken(token) {
        localStorage.setItem('token', token);
    }

    isLoggedIn() {
        try {
            if (this.getToken() != false) {
                const decodedJwt = this.decodeToken();

                if (decodedJwt.exp * 1000 < Date.now()) {
                    return false;
                }
                return true;
            }
            return false;
        } catch (err) {
            this.setToken(null);
            return false;
        }
    }

    logout() {
        this.setToken(null);
    }

    isAdmin() {
        var decodedJWT = this.decodeToken();
        return decodedJWT.role == 'admin' ? true : false;
    }

    getEmail() {
        var decodedJWT = this.decodeToken();
        return decodedJWT.email;

    }

    getROle() {
        var decodedJWT = this.decodeToken();
        return decodedJWT.role;
    }

    hasRole(roleName) {
        var decodedJWT = this.decodeToken();
        return decodedJWT.role == roleName ? true : false;
    }

    checkLoggedInWithRole(roles) {
        if (!this.isLoggedIn()) {
            window.location.href = "/";
        } else {
            var findIndex = roles.findIndex(r => r == this.getROle());
            if (findIndex == -1) {
                window.location.href = '/';
            }
        }
    }

}

export default new AuthService();