import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Products from '../../../services/Products';

export default class ServiceDetailsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
      units: []
    };
  }

  async componentDidMount() {
    var units = await Products.getUnits();
    this.state.units = units;
    this.forceUpdate();
  }

  onChange(e) {
    e.preventDefault();
    this.state.item[e.target.name] = e.target.value;
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        {this.props.item != null ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Dienstleistung Editieren</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form autoComplete='off' method='POST' onSubmit={async (e) => {
                e.preventDefault();
                var confirmation = window.confirm('Willst du wirklich diesen Eintrag speichern?');
                if (confirmation) {
                  var data = await Products.updateService(this.state.item);
                  if (data.failed == false) {
                    this.props.addOrUpdateService(e, data.data);
                    this.props.onHide(e);
                  }
                }
              }}>
                <Form.Group>
                  <Form.Label>Bezeichnung</Form.Label>
                  <Form.Control required required onChange={e => this.onChange(e)} name='name'
                    value={this.state.item.name == null ? '' : this.state.item.name} type='text' />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Beschreibung</Form.Label>
                  <Form.Control required name='description' max={512} onChange={(e) => this.onChange(e)}
                    value={this.state.item.description == null ? '' : this.state.item.description} placeholder='Beschreibung' />
                </Form.Group>
                <Row>
                  <Col md={6} className='pt-2 pb-2'>
                    <Form.Label>Preis pro Einheit (€)</Form.Label>
                    <Form.Group>
                      <Form.Control onChange={e => this.onChange(e)} name='unitPrice'
                        required value={this.state.item.unitPrice == null ? 0 : this.state.item.unitPrice} type='text' />
                    </Form.Group>
                  </Col>
                  <Col md={6} className='pt-2 pb-2'>
                    <Form.Group>
                      <Form.Label>Einheit</Form.Label>
                      <Form.Select className='bg-dark text-white' name='unitId' aria-label='Einheit auswählen' onChange={(e) => this.onChange(e)}
                        required value={this.state.item == null ? null : this.state.item.unitId}>
                        {this.state.units.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>{item.name}</option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Button className='bg-success mt-4 w-100' type='submit'>Speichern</Button>
                </Form.Group>

              </Form>
            </Modal.Body>

          </>

        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Dienstleistung Erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form autoComplete='off' method='POST' onSubmit={async (e) => {
                e.preventDefault();
                var confirmation = window.confirm('Willst du wirklich diesen Eintrag speichern?');
                if (confirmation) {
                  var data = await Products.insertService({
                    name: e.target.name.value,
                    description: e.target.description.value,
                    unitPrice: e.target.unitPrice.value,
                    unitId: e.target.unitId.value
                  });
                  if (data.failed == false) {
                    this.props.addOrUpdateService(e, data.data);
                    this.props.onHide(e);
                  }
                }
              }}>
                <Form.Group>
                  <Form.Label>Bezeichnung</Form.Label>
                  <Form.Control required required name='name' type='text' />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Beschreibung</Form.Label>
                  <Form.Control name='description' max={512}
                    required placeholder='Beschreibung' />
                </Form.Group>
                <Row>
                  <Col md={6} className='pt-2 pb-2'>
                    <Form.Label>Preis pro Einheit (€)</Form.Label>
                    <Form.Group>
                      <Form.Control required name='unitPrice'
                        type='text' />
                    </Form.Group>
                  </Col>
                  <Col md={6} className='pt-2 pb-2'>
                    <Form.Group>
                      <Form.Label>Einheit</Form.Label>
                      <Form.Select className='bg-dark text-white' required name='unitId' aria-label='Einheit auswählen'>
                        {this.state.units.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>{item.name}</option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Button className='bg-success mt-4 w-100' type='submit'>Speichern</Button>
                </Form.Group>

              </Form>
            </Modal.Body>
          </>
        )}
      </div>
    )
  }
}
