class DateTimeUtils {
    formatDateTime(date) {
        var date = new Date(date);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();

        var hour = date.getHours();
        var minutes = date.getMinutes();

        if (month >= 1 && month < 10) {
            month = "0" + month;
        }
        if (day >= 1 && day < 10) {
            day = "0" + day;
        }

        if (hour >= 0 && hour < 10) {
            hour = "0" + hour;
        }
        if (minutes >= 0 && minutes < 10) {
            minutes = "0" + minutes;
        }

        return day + "." + month + "." + year + " / " + hour + ":" + minutes;

    }
}

export default new DateTimeUtils();