import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import Products from '../../../services/Products';

export default class UnitDetailsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item
        };
    }

    render() {
        return (
            <div>
                {this.props.item != null ? (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Einheit Editieren</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        </Modal.Body>

                    </>

                ) : (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Einheit Erstellen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form autoComplete='off' method='POST' onSubmit={async (e) => {
                                e.preventDefault();

                                var confirmation = window.confirm('Willst du wirklich diesen Eintrag speichern?');
                                if (confirmation) {
                                    var data = await Products.insertUnit({
                                        name: e.target.name.value
                                    });
                                    this.props.addOrUpdateUnit(e, data.data);
                                    this.props.onHide(e);
                                }
                            }}>
                                <Form.Group>
                                    <Form.Control required type='text' name='name' placeholder='Einheit' />
                                </Form.Group>
                                <Form.Group>
                                    <Button className='bg-success mt-4 w-100' type='submit'>Speichern</Button>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </>
                )}
            </div>
        )
    }
}
