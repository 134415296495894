import React, { Component } from 'react'
import CustomerService from '../../services/CustomerService'
import { Button, Container, Modal, Table } from 'react-bootstrap';
import { FaPlus, FaEye, FaTrash, FaArchive } from "react-icons/fa";
import CustomerDetailsModal from './modals/CustomerDetailsModal'
import CountryService from '../../services/CountryService';
import AuthService from '../../services/AuthService';

export default class Customers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      customerModal: false,
      selectedCustomer: null
    };
  }

  componentWillMount() {
    AuthService.checkLoggedInWithRole(['user', 'admin']);
  }

  async componentDidMount() {
    var customers = await CustomerService.getCustomers();
    this.setState({ customers: customers.customers });
  }

  render() {
    return (
      <Container>
        <h3>Kunden</h3>
        <Button variant='success' style={{ width: '100%' }} onClick={(e) => {
          e.preventDefault();
          this.setState({
            customerModal: true,
            selectedCustomer: null
          });
        }}>
          <FaPlus /> Neuer Kunde
        </Button>

        <Table striped variant='dark' responsive className='mt-3 mb-3' bordered>
          <thead>
            <tr>
              <th scope='col'>Kunden-Nr.</th>
              <th scope='col'>Firma</th>
              <th scope='col'>Anrede, Nachname, Vorname</th>
              <th scope='col'>Adresse</th>
              <th scope='col'>PLZ</th>
              <th scope='col'>Ort, Land</th>


            </tr>
          </thead>
          <tbody>
            {this.state.customers
              .filter(o => o.archive === 0)
              .sort((a, b) => a.lastname.toLowerCase().localeCompare(b.lastname.toLowerCase()))
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.company}</td>
                    <td>
                      {item.male != null ? item.male == "w" ? "Frau" : "Herr" : "-"}, {item.lastname}, {item.firstname}</td>
                    <td>{item.address} {item.houseNumber}</td>
                    <td>{item.zip}</td>
                    <td>{item.city}, {CountryService.getCountry(item.country)}</td>
                    <td style={{ textAlign: 'right' }}>
                      <FaEye onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          customerModal: true,
                          selectedCustomer: item
                        })
                      }} />
                      {AuthService.hasRole('admin') ? (
                        <FaArchive
                          onClick={async (e) => {
                            e.preventDefault();
                            var cl = window.confirm("Löschen?");
                            if (cl) {
                              await CustomerService.deleteCustomer(item.id);
                              var customerIndex = this.state.customers.findIndex(x => x.id == item.id);
                              this.state.customers[customerIndex].archive = 1;
                              this.forceUpdate();
                            }
                          }}
                          style={{ marginLeft: 10, color: 'pink' }} />
                      ) : null}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>

        <Modal size='lg' onHide={() => this.setState({ customerModal: false, selectedCustomer: null })} show={this.state.customerModal}>
          <CustomerDetailsModal
            addCustomer={(mode, item) => {
              if (mode == "add") {
                this.state.customers.push(mode, item);
                this.state.customerModal = false;
                this.forceUpdate();
              } else if (mode == "edit") {
                var findIndex = this.state.customers.findIndex(o => o.id == item.id);
                this.state.customers[findIndex] = item;
                this.state.customerModal = false;
                this.forceUpdate();
              }
            }}
            mode={this.state.selectedCustomer == null ? "add" : "edit"}
            selectedCustomer={this.state.selectedCustomer}
          />
        </Modal>
      </Container>
    )
  }
}
