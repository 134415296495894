import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Products from '../../../services/Products';

export default class ServiceAddModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      services: [],
      selectedService: null
    }
  }

  async componentDidMount() {
    var services = await Products.getServices();
    this.setState({ services: services });
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Dienstleistung Hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            this.props.addToCart("service", this.state.selectedService);
          }} method='POST'>
            <Form.Group>
              <Form.Select
                aria-label='Dienstleistung auswählen'
                value={this.state.selectedService == null ? "" : this.state.selectedService.id}
                onChange={(e) => {
                  e.preventDefault();
                  var findService = this.state.services.find(o => o.id == e.target.value);
                  findService.quantity = 0;
                  this.state.selectedService = findService;
                  this.forceUpdate();
                }} required className='bg-dark text-white'>
                <option value="" disabled>---</option>
                {this.state.services.map((s, i) => {
                  return (
                    <option key={i} value={s.id}>{s.name}</option>
                  )
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Raum</Form.Label>
              <Form.Select name='roomId'
                defaultValue=''
                key='roomId'
                onChange={(e) => {
                  e.preventDefault();
                  var room = this.props.rooms.find(o => o.id == e.target.value);
                  this.state.selectedService.rooms = room;
                  this.forceUpdate();
                }}
                disabled={this.state.selectedService == null ? true : false}
                required className='bg-dark text-white'>
                <option value="" disabled>---</option>
                {this.props.rooms.map((item, index) => {
                  return (
                    <option key={index + "-room"} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
            </Form.Group>
            <Row className='pt-3 pb-3'>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Menge pro Einheit</Form.Label>
                  <Form.Control
                    className='bg-dark text-white'
                    disabled={
                      this.state.selectedService == null ? true : false
                    } type='text' name='quantity'
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.selectedService.quantity = e.target.value;
                      this.forceUpdate();
                    }} value={this.state.selectedService == null ? "" : this.state.selectedService.quantity} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Einheit</Form.Label>
                  <br />
                  <Form.Label>
                    {this.state.selectedService == null ? "" :
                      this.state.selectedService.unit.name}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Preis pro Einheit</Form.Label>
                  <br />
                  <Form.Label>
                    {this.state.selectedService == null ? "" : this.state.selectedService.unitPrice + " €"}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Gesamt</Form.Label>
              <br />
              <Form.Label>{this.state.selectedService == null ? 0.00 :
                (this.state.selectedService.unitPrice * this.state.selectedService.quantity)} €</Form.Label>
            </Form.Group>
            <Form.Group>
              <Button type='submit' className='w-100 mt-2' disabled={this.state.selectedService == null ? true : false}>
                Speichern
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </>
    )
  }
}
