import React, { Component } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'

import CustomerService from '../../../services/CustomerService'

export default class CustomerSelectionModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customers: []
    };
  }

  async componentDidMount() {
    var dc = await CustomerService.getCustomers();
    this.setState({ customers: dc.customers });
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Kundenauswahl</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table variant='dark' striped bordered responsive className='mt-2 mb-2'>
            <thead>
              <tr>
                <th scope='col'>Firma</th>
                <th scope='col'>Anrede, Nachname, Vorname</th>
                <th scope='col'>Adresse</th>
                <th scope='col'>PLZ</th>
                <th scope='col'>Ort, Land</th>

              </tr>
            </thead>
            <tbody>
              {this.state.customers.sort((a, b) => a.lastname.toLowerCase().localeCompare(b.lastname.toLowerCase()))
                .filter(o => o.archive == 0).map((item, index) => {
                  return (
                    <tr key={index} onClick={(e) => {
                      e.preventDefault();
                      this.props.setCustomer(item);
                    }}>
                      <td>{item.company}</td>
                      <td>{item.male != null ? item.male == "w" ? "Frau" : "Herr" : "-"}, {item.lastname}, {item.firstname}</td>
                      <td>{item.address} {item.houseNumber}</td>
                      <td>{item.zip}</td>
                      <td>{item.zip}, {item.country}</td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </Modal.Body>

      </>
    )
  }
}
