import React, { Component } from 'react'
import { Button, Container, Modal, Table } from 'react-bootstrap'
import UsersService from '../../services/UsersService'

import { FaArchive, FaEye, FaPlus } from 'react-icons/fa';
import AuthService from '../../services/AuthService';
import UserDetailsModal from './modals/UserDetailsModal';

export default class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      userDetailsModal: false,
      selectedUser: null
    };
  }

  componentWillMount() {
    AuthService.checkLoggedInWithRole(['admin']);
  }

  async componentDidMount() {
    var users = await UsersService.getUsers();
    this.setState({ users: users });
  }

  render() {
    return (
      <Container className='pt-3 pb-3'>
        <h3>Benutzer</h3>
        <Button
          onClick={(e) => {
            e.preventDefault();
            this.setState({
              userDetailsModal: true,
              selectedUserr: null
            });
          }}
          variant='success' className='w-100'>
          <FaPlus /> Neuer Benutzer
        </Button>
        <Table className='pt-2 pb-2' striped responsive variant='dark'>
          <thead>
            <tr>
              <th scope='col'>E-Mail</th>
              <th scope='col'>Rolle</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.filter(o => o.archive == 0 && o.email != AuthService.getEmail()).map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.email}</td>
                  <td>{item.role}</td>
                  <td style={{ textAlign: 'center' }}>
                    <FaEye onClick={(e) => {
                      this.setState({
                        selectedUser: item,
                        userDetailsModal: true
                      });
                    }} />
                    <FaArchive
                      onClick={async (e) => {
                        e.preventDefault();
                        var cd = window.confirm("Löschen?");
                        if (cd) {
                          var failed = await UsersService.deleteUser(item.email);
                          if (failed == false) {
                            var findIndex = this.state.users.findIndex(a => a.email == item.email);
                            this.state.users[findIndex].archive = 1;
                            this.forceUpdate();
                          } else {
                            alert("Es ist ein Fehler aufgetreten.");
                          }
                        }
                      }}
                      style={{ marginLeft: 10, color: 'pink' }} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        <Modal size='lg' show={this.state.userDetailsModal} onHide={() => {
          this.setState({ userDetailsModal: false, selectedUser: null });
        }}>
          <UserDetailsModal
            mode={this.state.selectedUser == null ? 'add' : 'edit'}
            prepareUser={(mode, item) => {
              if (mode == "edit") {
                var findIndex = this.state.users.findIndex(o => o.email == item.email);
                this.state.users[findIndex] = item;
                this.state.userDetailsModal = false;
                this.forceUpdate();
              } else if (mode == "add") {
                this.state.users.push(item);
                this.state.userDetailsModal = false;
                this.forceUpdate();
              }
            }}
            selectedUser={this.state.selectedUser} />
        </Modal>
      </Container>
    )
  }
}
