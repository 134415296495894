import React, { Component } from 'react'
import RoomsService from '../../services/RoomsService'
import AuthService from '../../services/AuthService';
import { Button, Container, Modal, Table } from 'react-bootstrap';
import { FaPlus, FaArchive } from "react-icons/fa";

import RoomsDetailsModal from './modals/RoomsDetailsModal'

export default class Rooms extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rooms: [],
      roomsEditorModal: false
    };
  }

  componentWillMount() {
    AuthService.checkLoggedInWithRole(['admin']);
  }

  async componentDidMount() {
    var rooms = await RoomsService.getRooms();
    this.setState({ rooms: rooms });
  }

  render() {
    return (
      <Container>
        <h3>Räume</h3>
        <Button onClick={(e) => {
          e.preventDefault();
          this.setState({ roomsEditorModal: true });
        }} variant='success' style={{ width: '100%' }}> <FaPlus /> Neuer Raum</Button>
        <br />
        <Table striped responsive variant='dark' className='mt-3 mb-3'>
          <thead>
            <tr>
              <th scope='col'>Bezeichnung</th>
            </tr>
          </thead>
          <tbody>
            {this.state.rooms.filter(a => a.archive == 0).map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td style={{ textAlign: 'right' }}>
                    <FaArchive style={{ color: 'pink' }} onClick={async (e) => {
                      e.preventDefault();
                      var wa = window.confirm("Archivieren?");
                      if (wa) {
                        var deleted = await RoomsService.deleteRoom(item.id);
                        if (deleted) {
                          var findIndex = this.state.rooms.findIndex(o => o.id == item.id);
                          this.state.rooms[findIndex].archive = 1;
                          this.forceUpdate();
                        }
                      }
                    }} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        <Modal onHide={() => {
          this.setState({ roomsEditorModal: false });
        }} size='lg' show={this.state.roomsEditorModal}>
          <RoomsDetailsModal onSubmit={(item) => {
            this.state.rooms.push(item);
            this.state.roomsEditorModal = false;
            this.forceUpdate();
          }} />
        </Modal>
      </Container>
    )
  }
}
