import React, { Component } from 'react'
import { Form, Card, Container, Row, Col, Button } from 'react-bootstrap'

import AuthService from '../services/AuthService'

export default class Login extends Component {

  async login(e) {
    e.preventDefault();
    var login = await AuthService.login(e.target.email.value, e.target.password.value);
    if (login) {
      window.location.href = '/profile';
    } else {
      alert("Deine E-Mail Adresse oder Passwort ist falsch.")
    }
  }

  componentWillMount() {
    if (AuthService.isLoggedIn()) {
      window.location.href = '/profile';
    }
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col md={6} className='mx-auto'>
              <Card className='pt-5' style={{ backgroundColor: 'black', color: 'white' }}>
                <Card.Body>
                  <h3 className='text-center'>
                    Anmeldung
                  </h3>
                  <form autoComplete='off' method='POST' onSubmit={async (e) => await this.login(e)}>
                    <Form.Group>
                      <Form.Control name='email' required className='login-input' type='email' placeholder='E-Mail' />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control name='password' required className='login-input' type='password'
                        placeholder='Passwort' />
                    </Form.Group>
                    <Form.Group>
                      <Button className='w-100' type='submit'>Anmelden</Button>
                    </Form.Group>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
