import AuthService from './AuthService'

class RoomsService {
    async getRooms() {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL
            + "/rooms/getRooms", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data.rooms;
    }

    async insertRoom(room) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL
            + "/rooms/insertRoom", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify(room)
        });
        var data = await response.json();
        return data.rooms;
    }

    async deleteRoom(roomId) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL
            + "/rooms/deleteRoom/" + roomId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data.failed;
    }


}

export default new RoomsService();