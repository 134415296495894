import { Button, Form, Modal } from 'react-bootstrap';
import React, { Component } from 'react'

import UsersService from '../../../services/UsersService'

export default class UserDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: this.props.selectedUser
        };
    }

    onChange(e) {
        e.preventDefault();
        var user = this.state.selectedUser == null ? {} : this.state.selectedUser;
        user[e.target.name] = e.target.value;
        this.state.selectedUser = user;
        this.forceUpdate();
    }

    render() {
        return (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>Benutzer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form method='POST' onSubmit={async (e) => {
                        e.preventDefault();
                        var cui = window.confirm('Speichern?');
                        if (cui) {
                            var data = await UsersService.insertOrUpdateuser(this.props.mode, this.state.selectedUser);
                            if (data.failed) {
                                alert(data.message);
                            } else {
                                this.props.prepareUser(this.props.mode, data.users);
                            }
                        }
                    }}>
                        <Form.Group>
                            <Form.Label>E-Mail</Form.Label>
                            <Form.Control
                                required={this.props.mode == 'add'}
                                disabled={this.props.mode == 'edit' ? true : false}
                                onChange={(e) => this.onChange(e)}
                                className={this.state.selectedUser != null ? 'bg-dark' : ''}
                                value={this.state.selectedUser == null ? "" : this.state.selectedUser.email}
                                name="email" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Passwort</Form.Label>
                            <Form.Control name='password' type='password'
                                required={this.props.mode != 'edit' ? true : false}
                                onChange={(e) => this.onChange(e)}
                                placeholder='Passwort'
                                value={this.state.selectedUser == null ? '' : this.state.selectedUser.password}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Rolle</Form.Label>
                            <Form.Select
                                name='role'
                                required
                                onChange={(e) => this.onChange(e)}
                                className='bg-dark text-white'
                                value={this.state.selectedUser == null ? '' : this.state.selectedUser.role}>
                                <option value='' disabled>-</option>
                                <option value={'user'}>Benutzer</option>
                                <option value={'admin'}>Admin</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Button type='submit' variant='success'>
                                Speichern
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </div>
        )
    }
}
