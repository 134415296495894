class PaginationUtils {
    generate(currentPage, data, recordsPerPage) {
        recordsPerPage = 10;
        var lastIndex = currentPage * recordsPerPage;
        var firstIndex = lastIndex - recordsPerPage;
        var records = data.slice(firstIndex, lastIndex);
        var npage = Math.ceil(data.length / 10);
        var numbers = [...Array(npage + 1).keys()].slice(1);

        return {
            records: records,
            numbers: numbers
        }
    }
}
export default new PaginationUtils();