import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import CountryService from '../../../services/CountryService';
import CustomerService from '../../../services/CustomerService';

export default class CustomerDetailsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customer: this.props.selectedCustomer
    };
  }

  onChangeCustomerData(e) {
    e.preventDefault();

    var customer = this.state.customer == null ? {} : this.state.customer;
    customer[e.target.name] = e.target.value;
    this.state.customer = customer;
    this.forceUpdate();
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Kunde</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form method='POST' className='p-4' onSubmit={async (e) => {
              e.preventDefault();
              var cf = window.confirm("Speichern?");
              if (cf) {
                if (this.props.mode == "add") {
                  var data = await CustomerService.insertCustomer(this.state.customer);
                  this.props.addCustomer("add", data.customer);
                } else if (this.props.mode == "edit") {
                  var data = await CustomerService.updateCustomer(this.state.customer);
                  this.props.addCustomer("edit", data.customer);
                }
              }
            }}>
              <Row className='pt-2 pb-2'>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Firma</Form.Label>
                    <Form.Control type='text' placeholder='Firma'
                      name='company'
                      value={this.state.customer == null ? "" : this.state.customer.company}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='pt-2 pb-2'>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Anrede</Form.Label>
                    <Form.Select
                      name='male'
                      onChange={(e) => this.onChangeCustomerData(e)}
                      required
                      className='bg-dark text-white'
                      value={this.state.customer == null ? '' :
                        this.state.customer.male == null ? '' : this.state.male}>
                      <option value={null}>-</option>
                      <option value='w'>Frau</option>
                      <option value='m'>Herr</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control
                      required
                      type='text' placeholder='Vorname'
                      name='firstname'
                      value={this.state.customer == null ? "" : this.state.customer.firstname}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control
                      required
                      type='text' placeholder='Nachname'
                      name='lastname'
                      value={this.state.customer == null ? "" : this.state.customer.lastname}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='pt-2 pb-2'>
                <Col md={8}>
                  <Form.Group>
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control required type='text' placeholder='Adresse'
                      name='address'
                      value={this.state.customer == null ? "" : this.state.customer.address}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Hausnummer</Form.Label>
                    <Form.Control required type='text' placeholder='Hausnummer'
                      name='houseNumber'
                      value={this.state.customer == null ? "" :
                        this.state.customer.houseNumber}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>PLZ</Form.Label>
                    <Form.Control required type='text' placeholder='PLZ'
                      name='zip'
                      value={this.state.customer == null ? "" : this.state.customer.zip}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Stadt</Form.Label>
                    <Form.Control required type='text' placeholder='Stadt'
                      name='city'
                      value={this.state.customer == null ? "" : this.state.customer.city}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='pt-2 pb-2'>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Land</Form.Label>
                    <Form.Select
                      required
                      className='bg-dark text-white'
                      name='country'
                      onChange={(e) => this.onChangeCustomerData(e)}
                      value={this.state.customer == null ? "" : this.state.customer.country}
                      placeholder='Land'>
                      <option key={'option-none'} value='' disabled></option>
                      {Object.keys(CountryService.getCountries()).map(item => {
                        return (<option key={'op-' + item} value={item}>{CountryService.getCountry(item)}</option>)
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className='pt-2 pb-2'>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>E-Mail</Form.Label>
                    <Form.Control name='email' type='email' placeholder='E-Mail'
                      value={this.state.customer == null ? "" : this.state.customer.email}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control name='tel' type='tel' placeholder='Telefon'
                      value={this.state.customer == null ? "" : this.state.customer.tel}
                      onChange={(e) => this.onChangeCustomerData(e)} />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className='mt-3 mb-2'>
                <Button className='w-100' variant='success' type='submit'>Speichern</Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </>
    )
  }
}
