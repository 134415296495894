import AuthService from './AuthService'

class Products {
    async getServices() {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/services/getServices', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data.data;
    }

    async deleteService(id) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/services/deleteService/' + id, {
            method: 'GET',
            headers: {
                'Origin': 'https://app.systems.daheim-koeln.com',
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        console.log(data);
    }

    async updateService(item) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/services/updateService', {
            method: 'POST',
            headers: {
                'Origin': 'https://app.systems.daheim-koeln.com',
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify(item)
        });
        var data = await response.json();
        return data;
    }

    async insertService(body) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/services/insertService', {
            method: 'POST',
            headers: {
                'Origin': 'https://app.systems.daheim-koeln.com',
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify(body)
        });;
        var data = await response.json();
        return data;
    }

    async getMaterials() {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/materials/getMaterials', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data.data;
    }

    async deleteMaterial(id) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/materials/deleteMaterial/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data;
    }

    async updateMaterial(item) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/materials/updateMaterial', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify(item)
        });
        var data = await response.json();
        return data;
    }

    async insertMaterial(body) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/materials/insertMaterial', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify(body)
        });
        var data = await response.json();
        return data;
    }

    async getUnits() {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + "/units/getUnits", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data.data;
    }

    async insertUnit(body) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + "/units/insertUnit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify(body)
        });
        var data = await response.json();
        return data;
    }

    async deleteUnit(unitId) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + "/units/deleteUnit/" + unitId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data;
    }
}

export default new Products();