import React, { Component } from 'react'
import { useParams } from 'react-router-dom';
import OrdersService from '../../services/OrdersService';
import { Alert, Button, Container, Modal, Table } from 'react-bootstrap';
import AuthService from '../../services/AuthService';
import RoomsService from '../../services/RoomsService';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class OrderInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      rooms: [],
      modalOpen: false,
      selectedService: null
    };
  }

  async componentDidMount() {
    if (!AuthService.isLoggedIn()) {
      window.location.href = '/';
    }
    var order = await OrdersService.getOrder(this.props.params.id);
    if (order.order != null) {
      this.setState({
        order: order.order,
        rooms: await RoomsService.getRooms()
      });
    }
  }

  getAddresstoString() {
    return this.state.order.customer.address + " " + this.state.order.customer.houseNumber + " - " +
      this.state.order.customer.zip + " " + this.state.order.customer.city + ' - '
      + this.state.order.customer.country;
  }

  getMaterialTotal(serviceId, roomId) {
    var filter = this.state.order.orderDetails.find(
      o => o.id == serviceId && o.roomId == roomId
    );
    var summe = 0;
    filter.materials.forEach(m => {
      summe += (m.quantity * m.unitPrice);
    });
    return summe.toFixed(2);
  }

  getRoomName(roomId) {
    return this.state.rooms.find(o => o.id == roomId).name;
  }

  getAllTotalMaterial() {
    var summe = 0;
    this.state.order.orderDetails.forEach(s => {
      if (s.materials.length != 0) {
        s.materials.forEach(m => {
          summe += (m.quantity * m.unitPrice);
        })
      }
    });
    return summe.toFixed(2);
  }

  getAllServicesTotal() {
    var summe = 0;
    this.state.order.orderDetails.forEach(s => {
      summe += (s.quantity * s.unitPrice);
    });
    return summe.toFixed(2);
  }

  getTotalWithTax() {
    var tsm = parseFloat(this.getAllServicesTotal()) +
      parseFloat(this.getAllTotalMaterial());
    var withTax = tsm * 1.19;
    return withTax.toFixed(2);
  }

  formatStatus(status) {
    if (status == 0) {
      return "Ausstehend";
    } else if (status == 1) {
      return "In Bearbeitung"
    } else if (status == 3) {
      return "Fertig";
    } else if (status == 5) {
      return "Storniert";
    }
    return null;

  }

  render() {
    return (
      <>
        {this.state.order.length == 0 ? (
          <div style={{ fontWeight: 'bold', paddingLeft: 15, paddingTop: 10, paddingBottom: 10, backgroundColor: 'red', color: 'white' }}>
            <p>Es wurde kein Auftrag gefunden!</p>
          </div>
        ) : (
          <Container className='pt-3 pb-5'>
            <h3>AUFTRAG #{this.state.order.id}</h3>
            <p className='font-weight-bold'>Dok.-Typ: {this.state.order.docType == 0 ? "Kostenvoranschlag" : "Rechnung"}</p>
            <Table responsive variant='dark'>
              <tbody>
                <tr>
                  <td>Gesamt (Material)</td>
                  <td>{this.getAllTotalMaterial()} €</td>
                </tr>
                <tr>
                  <td>Gesamt (Dienstleistungen)</td>
                  <td>{this.getAllServicesTotal()} €</td>
                </tr>
                <tr>
                  <td>Gesamt ohne MwSt.</td>
                  <td>{parseFloat(this.getAllServicesTotal()) + parseFloat(this.getAllTotalMaterial())} €</td>
                </tr>
                <tr>
                  <td>Gesamt mit MwSt.</td>
                  <td>{this.getTotalWithTax()} €</td>
                </tr>
              </tbody>
            </Table>
            <div>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>Kunden-Nr.</td>
                    <td>{this.state.order.customer.id}</td>
                  </tr>
                  <tr>
                    <td>Firma</td>
                    <td>{this.state.order.customer.company == undefined ? "-" :
                      this.state.order.customer.company}</td>
                  </tr>
                  <tr>
                    <td>Anrede, Nachname, Vorname</td>
                    <td>{this.state.order.customer.male != null ? this.state.order.customer.male == "w" ? "Frau" : "Herr" : "-"}, {this.state.order.customer.lastname}, {this.state.order.customer.firstname}</td>
                  </tr>
                  <tr>
                    <td>Adresse</td>
                    <td>{this.getAddresstoString()}</td>
                  </tr>
                  <tr>
                    <td>E-Mail</td>
                    <td>{this.state.order.customer.email}</td>
                  </tr>
                  <tr>
                    <td>Telefon</td>
                    <td>{this.state.order.customer.tel}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br />
            <h4>DETAILS</h4>
            <Table striped variant='dark' responsive>
              <thead>
                <tr>
                  <th scope='col'>Pos.</th>
                  <th scope='col'>Bezeichnung</th>
                  <th scope='col'>Raum</th>
                  <th scope='col'>Gesamt (Material)</th>
                  <th scope='col'>Gesamt (Dienstleistung)</th>
                  <th scope='col'>Gesamt (MwSt)</th>
                </tr>

              </thead>
              <tbody>
                {this.state.order.orderDetails.map((item, index) => {
                  return (
                    <tr onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        modalOpen: true,
                        selectedService: item
                      });
                    }} key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name} ({item.quantity} {item.unit.name})</td>
                      <td>{this.getRoomName(item.roomId)}</td>
                      <td>{this.getMaterialTotal(item.id, item.roomId)} €</td>
                      <td>{item.quantity * item.unitPrice} €</td>
                      <td>
                        {this.getMaterialTotal(item.id, item.roomId) + (item.quantity * item.unitPrice)} €
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            {this.state.order.status == 0 ? (
              <Button variant='success' onClick={async (e) => {
                e.preventDefault();
                var statusChanger = await OrdersService.changeStatus(
                  this.state.order.id,
                  1
                );
                this.state.order = statusChanger.order;
                this.forceUpdate();
              }}>
                Annehmen
              </Button>
            ) : null}
            {this.state.order.status == 1 ? (
              <Button variant='success' onClick={async (e) => {
                e.preventDefault();
                var statusChanger = await OrdersService.changeStatus(
                  this.state.order.id,
                  3
                );
                this.state.order = statusChanger.order;
                this.forceUpdate();
              }}>
                Fertig
              </Button>
            ) : null}

            {this.state.order.status != 5 && this.state.order.status != 3 ? (
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  var cf = window.confirm("Willst du wirklich diesen Auftrag stornieren?");
                  if (cf) {
                    var data = await OrdersService.changeStatus(this.state.order.id, 5);
                    this.state.order = data.order;
                    this.forceUpdate();
                  }
                }}
                style={{ marginLeft: 10, backgroundColor: 'red', color: 'white' }}>
                Stornieren
              </Button>
            ) : null}


            <Modal show={this.state.modalOpen}
              size='lg' centered onHide={(e) => {
                this.setState({
                  modalOpen: false,
                  selectedService: null
                });
              }}>
              {this.state.selectedService != null ? (
                <>
                  <Modal.Header>
                    <Modal.Title>
                      {this.state.selectedService.name}
                    </Modal.Title>
                    <strong>{this.getRoomName(
                      this.state.selectedService.roomId
                    )}</strong>
                  </Modal.Header>
                  <Modal.Body>
                    <Table striped variant='dark'>
                      <thead>
                        <tr>
                          <th scope='col'>Pos.</th>
                          <th scope='col'>Bezeichnung</th>
                          <th scope='col'>Menge pro Einheit</th>
                          <th scope='col'>Preis pro Einheit</th>
                          <th scope='col'>Gesamt ohne MwSt</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selectedService.materials.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}.</td>
                              <td>{item.name}</td>
                              <td>{item.quantity} {item.unit.name}</td>
                              <td>{item.unitPrice} €</td>
                              <td>{item.unitPrice * item.quantity} €</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Modal.Body>
                </>
              ) : null}
            </Modal>
          </Container >
        )
        }
      </>
    )
  }
}
export default withParams(OrderInfo);