import React, { Component } from 'react'
import { Breadcrumb, Button, ButtonGroup, Container, Modal, Pagination, Table } from 'react-bootstrap'

import Products from '../../services/Products'
import PaginationUtils from '../../utils/PaginationUtils'

import { FaPlus, FaTrash } from "react-icons/fa";
import AuthService from '../../services/AuthService';
import UnitDetailsModal from './modals/UnitDetailsModal'


export default class Units extends Component {

  constructor(props) {
    super(props);
    this.state = {
      units: [],
      currentPage: 1,
      records: [],
      numbers: [],
      selectedItem: null,
      modalOpen: false
    }
  }

  componentWillMount() {
    AuthService.checkLoggedInWithRole(['admin']);
  }

  async componentDidMount() {
    var data = await Products.getUnits();
    var pagination = PaginationUtils.generate(
      this.state.currentPage,
      data,
      5
    );
    this.setState({
      units: data,
      records: pagination.records,
      numbers: pagination.numbers
    })
  }

  changePage(e, n) {
    e.preventDefault();
    var pagination = PaginationUtils.generate(n, this.state.units, 5);
    this.setState({
      currentPage: n,
      records: pagination.records,
      numbers: pagination.numbers
    });
  }


  render() {
    return (
      <>
        <Container>
          <h3 className='pt-4'>EINHEITEN ({this.state.units.length})</h3>
          {AuthService.isAdmin() ? (
            <Button className='mt-3 w-100' onClick={(e) => {
              e.preventDefault();
              this.setState({ modalOpen: true, selectedItem: null });
            }}>
              <FaPlus /> EINHEIT ANLEGEN
            </Button>
          ) : null}
          <Table responsive striped variant='dark' bordered hover className='mt-5 mb-5'>
            <thead>
              <tr>
                <th>Einheit</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {this.state.records.map(((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {AuthService.isAdmin() ? (
                        <Button onClick={async (e) => {
                          e.preventDefault();
                          var confirmation = window.confirm('Willst du wirklich diese Dienstleistung löschen?');
                          if (confirmation) {
                            await Products.deleteUnit(item.id);
                            var sIndex = this.state.units.findIndex(o => o.id == item.id);
                            this.state.units.splice(sIndex, 1);
                            this.state.records.splice(index, 1);

                            this.forceUpdate();
                          }
                        }} style={{ marginLeft: 5 }} className='bg-danger'>
                          <FaTrash />
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                )
              }))}
            </tbody>
          </Table>
          <Pagination>
            {this.state.numbers.map((item, i) => {
              return (
                <Pagination.Item onClick={(e) => this.changePage(e, item)}
                  key={i} active={item == this.state.currentPage}>
                  {item}
                </Pagination.Item>
              )
            })}
          </Pagination>
          <Modal show={this.state.modalOpen} onHide={() => {
            this.setState({ modalOpen: false, selectedItem: null });
          }} background="static" keyboard={false}>
            <UnitDetailsModal onHide={(e) => {
              e.preventDefault();
              this.setState({ modalOpen: false, selectedItem: null });
            }}
              addOrUpdateUnit={(e, item) => {
                if (this.state.selectedItem != null) {
                } else {
                  this.state.units.push(item);
                  this.forceUpdate();
                  this.changePage(e, this.state.currentPage);
                }
              }}
              item={this.state.selectedItem} />
          </Modal>
        </Container>
      </>
    )
  }
}