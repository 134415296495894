import AuthService from './AuthService'

class CustomerService {
    async getCustomers() {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + "/customers/getCustomers", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "user-token": AuthService.getToken()
            }
        });
        var data = await response.json();
        return data;
    }

    async insertCustomer(customerData) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + "/customers/saveOrUpdateCustomer", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "user-token": AuthService.getToken()
            },
            body: JSON.stringify({
                ...customerData,
                mode: 'add'
            })
        });
        var data = await response.json();
        return data;
    }

    async updateCustomer(customerData) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + "/customers/saveOrUpdateCustomer", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "user-token": AuthService.getToken()
            },
            body: JSON.stringify({
                ...customerData,
                mode: 'edit'
            })
        });
        var data = await response.json();
        return data;
    }

    async deleteCustomer(customerId) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + "/customers/deleteCustomer/" + customerId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "user-token": AuthService.getToken()
            }
        });
        return true;
    }
}

export default new CustomerService();