import React, { Component } from 'react'
import { Card, Container, Row, Table } from 'react-bootstrap'
import AuthService from '../services/AuthService'

export default class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dateTime: ''
    };
  }

  componentDidMount() {
    this.setState({ dateTime: this.getDateTimeString() });
  }


  getDateTimeString() {
    var date = new Date();
    var dateTimeString = "";

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    var hour = date.getHours();
    var minutes = date.getMinutes();

    if (month >= 1 && month < 10) {
      month = "0" + month;
    }
    if (day >= 1 && day < 10) {
      day = "0" + day;
    }

    if (hour >= 0 && hour < 10) {
      hour = "0" + hour;
    }
    if (minutes >= 0 && minutes < 10) {
      minutes = "0" + minutes;
    }

    return day + "." + month + "." + year + " / " + hour + ":" + minutes;

  }

  render() {
    setInterval(() => {
      this.setState({ dateTime: this.getDateTimeString() });
    }, 500);
    return (
      <>
        <Container className='pt-5'>
          <Card className='text-white'>
            <Card.Body>
              <Card.Title>Datum / Uhrzeit</Card.Title>
              <Card.Subtitle>{this.state.dateTime}</Card.Subtitle>
            </Card.Body>
          </Card>
          <Card className='text-white mt-2'>
            <Card.Header>
              <Card.Title>Profilinformationen</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Subtitle>e-Mail: {AuthService.getEmail()}</Card.Subtitle>
            </Card.Body>
          </Card>
          <Card className='text-white mt-2'>
            <Card.Header>
              <Card.Title>App-Version</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Subtitle>
                Version: v{process.env.REACT_APP_VERSION}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Container>
      </>
    )
  }
}
