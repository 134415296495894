import React, { Component } from 'react'
import { Form, Modal } from 'react-bootstrap'
import RoomsService from '../../../services/RoomsService';

export default class RoomsDetailsModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>Neuer Raum</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form method='POST' onSubmit={async (e) => {
                        e.preventDefault();
                        var wc = window.confirm("Speichern?");
                        if(wc) {
                            var data = await RoomsService.insertRoom({
                                name: e.target.name.value
                            });
                            this.props.onSubmit(data);
                        }
                    }}>
                        <Form.Group>
                            <Form.Label>Bezeichnung</Form.Label>
                            <Form.Control required type='text' placeholder='Bezeichnung' name='name' />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control style={{ backgroundColor: 'green' }} type='submit' value='Absenden' />
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </div>
        )
    }
}
