import React, { Component } from 'react'

import OrdersService from '../../services/OrdersService';
import CustomerSelectioNmodal from './modals/CustomerSelectionModal'
import ServiceAddModal from './modals/ServiceAddModal'
import { FaDivide, FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import MaterialAddModal from './modals/MaterialAddModal'
import { Button, Container, Form, Modal, Table } from 'react-bootstrap';
import AuthService from '../../services/AuthService';

import RoomsService from '../../services/RoomsService'

export default class AddOrder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      customer: null,
      cart: [],
      cart: [],
      rooms: [],
      doctype: 0,
      customerSelectionModal: false,
      serviceAddModal: false,
      materialAddModal: false,
      selectedServiceIndex: null,
      openDetailsModal: false
    };
  }

  async componentDidMount() {
    var rooms = await RoomsService.getRooms();
    this.state.rooms = rooms.filter(o => o.archive == 0);
    this.forceUpdate();
  }

  async syncRooms() {
    var romms = await RoomsService.getRooms();
    this.state.rooms = romms;
    this.forceUpdate();
  }

  canSendOrder() {
    if (!AuthService.isLoggedIn()) {
      window.location.href = '/';
    }
    if (this.state.customer == null || this.state.doctype == null ||
      this.state.cart.length == 0) {
      return true;
    }
    return false;
  }

  async redirectToOrders() {
    console.log("TEST");
  }

  addService(item) {
    var findIndex = this.state.cart.findIndex(o => o.id == item.id && o.rooms.id == item.rooms.id);
    if (findIndex == -1) {
      item.materials = [];
      this.state.cart.push(item);
    }
  }

  addMaterial(item) {
    var findIndex = this.state.cart.findIndex(m => m.materials.id == item.id && m.id == this.state.selectedServiceIndex);
    if (findIndex == -1) {
      this.state.cart[this.state.selectedServiceIndex].materials.push(item);
    } else {
      this.state.cart[this.state.selectedServiceIndex].materials[findIndex] = item;
    }
  }

  getServicesTotal() {
    if (this.state.cart.length == 0) return 0;
    var summe = 0;
    this.state.cart.forEach((item) => {
      var x = item.unitPrice * item.quantity;
      summe += x;
    });
    return summe;
  }

  getMaterialsTotal() {
    if (this.state.cart.length == 0) return 0;
    var summe = 0;
    this.state.cart.forEach(item => {
      if (item.materials.length != 0) {
        item.materials.forEach(m => {
          var x = m.unitPrice * m.quantity;
          summe += x;
        });
      }
    });
    return summe;
  }

  getTotal() {
    var total = this.getServicesTotal() + this.getMaterialsTotal();
    var withTax = total * 1.19;
    return withTax;
  }

  getMaterialGesamt(serviceId, roomId) {
    var find = this.state.cart.findIndex(o => o.id == serviceId && o.rooms.id == roomId);
    if (this.state.cart[find].materials.length == 0) return 0;
    var summe = 0;
    this.state.cart[find].materials.forEach((e) => {
      var x = e.unitPrice * e.quantity;
      summe += x;
    });
    return summe;
  }

  removeService(serviceId, roomId) {
    var deleteConfirm = window.confirm("Möchtest du wirklich die Dienstleistung aus der Bestellung entfernen?");
    if (deleteConfirm) {
      var getServiceIndex = this.state.cart.findIndex(o => o.id == serviceId && o.rooms.id == roomId);
      this.state.cart.splice(getServiceIndex, 1);
      this.forceUpdate();
    }
  }

  removeMaterial(materialIndex) {
    var deleteConfirm = window.confirm("Willst du wirklich dieses Material aus dieser Dienstleistung entfernen?");
    if (deleteConfirm) {
      this.state.cart[this.state.selectedServiceIndex].materials.splice(materialIndex, 1);
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Container className='pt-5 pb-5'>
        <h3 className='text-uppercase'>Neue Bestellung (Schritt {this.state.step + 1})</h3>

        {this.state.step == 0 ? (
          <>
            <Form.Group>
              <Form.Label>Dok.-Typ</Form.Label>
              <Form.Select onChange={(e) => {
                this.state.doctype = e.target.value;
                this.forceUpdate();
              }} value={this.state.doctype} className='bg-dark text-white'>
                <option value='0'>Kostenvoranschlag</option>
                <option value='1'>Rechnung</option>
              </Form.Select>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Kunde Auswählen</Form.Label>
              <Form.Control placeholder='Kunde auwählen' onClick={(e) => {
                e.preventDefault();
                this.state.customerSelectionModal = true;
                this.forceUpdate();
              }}
                onChange={(e) => {
                  e.preventDefault();
                }}
                value={this.state.customer == null ?
                  "-" :
                  (this.state.customer.company == "" || this.state.customer.company == null ||
                    this.state.customer.company == "-" ? this.state.customer.lastname + ", " + this.state.customer.firstname : this.state.customer.company)}
              />
            </Form.Group>
            <Form.Group>
              <Button onClick={(e) => {
                e.preventDefault();
                if (this.state.customer == null) {
                  alert("Bitte wähle einen Kunde aus.");
                } else {
                  this.state.step = 1;
                  this.forceUpdate();
                }
              }} className='bg-success w-100 mt-5'>
                Weiter
              </Button>
            </Form.Group>

          </>
        ) : null}
        {this.state.step == 1 ? (
          <>
            <Button className='w-100 bg-success' onClick={async (e) => {
              e.preventDefault();
              await this.syncRooms();
              this.state.serviceAddModal = true;
              this.forceUpdate();
            }}>Dienstleistung Hinzufügen</Button>

            <Table variant='dark' striped hover>
              <thead>
                <tr>
                  <th scope='col'>Pos.</th>
                  <th scope='col'>Bezeichnung</th>
                  <th scope='col'>Fläche</th>
                  <th scope='col'>Dienstleistung</th>
                  <th scope='col'>Material</th>
                  <th scope='col'>Gesamt</th>
                </tr>
              </thead>
              <tbody>
                {this.state.cart.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name} ({item.quantity} {item.unit.name})</td>
                      <td>{item.rooms.name}</td>
                      <td>{item.unitPrice * item.quantity} €</td>
                      <td>{this.getMaterialGesamt(item.id, item.rooms.id)} €</td>
                      <td>{this.getMaterialGesamt(item.id, item.rooms.id) + (item.unitPrice * item.quantity)} €</td>
                      <td>
                        <FaEye onClick={e => {
                          e.preventDefault();
                          this.setState({
                            openDetailsModal: true,
                            selectedServiceIndex: index
                          });
                        }} />
                      </td>
                      <td>
                        <FaTrash onClick={e => {
                          e.preventDefault();
                          this.removeService(item.id, item.rooms.id);
                        }} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            <Table variant='dark' striped>
              <tbody key={'gesamt'}>
                <tr key={'total-1'}>
                  <td>Gesamt (Dienstleistung ohne MwSt)</td>
                  <td>{this.getServicesTotal().toFixed(2)} €</td>
                </tr>
                <tr key={'total-2'}>
                  <td>Gesamt (Material) ohne MwSt.</td>
                  <td>{this.getMaterialsTotal().toFixed(2)} €</td>
                </tr>
                <tr key={'total-3'}>
                  <td>Gesamt mit MwSt inkl. Material</td>
                  <td>{this.getTotal().toFixed(2)} €</td>
                </tr>
              </tbody>
            </Table>

            <Button variant='success' className='w-100 mt-2'
              disabled={this.state.cart.length == 0 ? true : false}
              onClick={async (e) => {
                e.preventDefault();
                var cf = window.confirm("Speichern?");
                if (cf) {
                  await OrdersService.insertOrder(
                    this.state.doctype,
                    this.state.customer,
                    this.state.cart
                  );
                  window.location.href = '/orders';
                }

              }}>
              Speichern
            </Button>
          </>
        ) : null}


        <Modal size='xl' centered onHide={(e) => {
          this.setState({ customerSelectionModal: false });
        }} show={this.state.customerSelectionModal}>
          <CustomerSelectioNmodal
            setCustomer={(customer) => {
              this.state.customer = customer;
              this.state.customerSelectionModal = false;
              this.forceUpdate();
            }}
          />
        </Modal>

        {/* ServiceAddModal */}
        <Modal onHide={() => {
          this.state.serviceAddModal = false;
          this.forceUpdate();
        }} show={this.state.serviceAddModal}>
          <ServiceAddModal
            rooms={this.state.rooms}
            addToCart={(type, item) => {
              if (type == "service") {
                this.addService(item);
                this.setState({ serviceAddModal: false });
              }
            }} />
        </Modal>
        <Modal onHide={() => {
          this.state.materialAddModal = false;
          this.forceUpdate();
        }} show={this.state.materialAddModal}>
          <MaterialAddModal addToCart={(item) => {
            this.addMaterial(item);
            // this.state.selectedServiceIndex = null;
            this.state.materialAddModal = false;
            this.forceUpdate();
          }} />
        </Modal>


        {/* ServiceDetailsModal */}
        <Modal show={this.state.openDetailsModal}
          size='lg' onHide={(e) => {
            this.setState({ openDetailsModal: false });
          }}>
          {this.state.selectedServiceIndex == null ? null : (
            <>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.cart[this.state.selectedServiceIndex].name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button onClick={e => {
                  e.preventDefault();
                  this.setState({ materialAddModal: true });
                }} className='w-100 mb-2 mt-2'>Material Anlegen</Button>
                <Table variant='dark' striped>

                  <thead>
                    <tr>
                      <th scope='col'>Pos.</th>
                      <th scope='col'>Bezeichnung</th>
                      <th scope='col'>Preis pro Einhiet</th>
                      <th scope='col'>Gesmat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.cart[this.state.selectedServiceIndex].materials.map((m, mIndex) => {
                      return (
                        <tr key={mIndex}>
                          <td>{mIndex + 1}.</td>
                          <td>{m.name} ({m.quantity} {m.unit.name})</td>
                          <td>{m.unitPrice} €</td>
                          <td>{m.unitPrice * m.quantity} € €</td>
                          <td>
                            <FaTrash onClick={e => {
                              e.preventDefault();
                              this.removeMaterial(
                                mIndex
                              );
                            }} />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <p className='font-weight-bold'>
                  {this.state.cart[this.state.selectedServiceIndex].rooms.name}
                </p>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </Container>
    )
  }
}