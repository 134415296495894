import AuthService from './AuthService'

class UsersService {

    async getUsers() {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL
            + "/users/getUsers", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data.users;
    }

    async deleteUser(userEmail) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL
            + "/users/deleteUser", {
            method: 'POst',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify({
                email: userEmail
            })
        });
        var data = await response.json();
        return data.failed;
    }


    async insertOrUpdateuser(mode, user) {
        user.mode = mode;
        var response = await fetch(process.env.REACT_APP_API_BASE_URL
            + "/users/insertOrUpdateUser", {
            method: 'POst',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify(user)
        });
        var data = await response.json();
        return data;
    }

}

export default new UsersService();