import AuthService from './AuthService'

class OrdersService {
    async getOrders() {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL + '/orders/getOrders', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data;
    }

    async getOrder(id) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL +
            '/orders/getOrder/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            }
        });
        var data = await response.json();
        return data;
    }

    async changeStatus(orderId, status) {
        var response = await fetch(process.env.REACT_APP_API_BASE_URL
            + "/orders/changeStatus", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify({
                orderId: orderId,
                status: status
            })
        });
        var data = await response.json();
        return data;
    }

    async insertOrder(docType, customer, orderDetails) {
        await fetch(process.env.REACT_APP_API_BASE_URL
            + "/orders/insertOrder", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user-token': AuthService.getToken()
            },
            body: JSON.stringify({
                docType: docType,
                customer: customer,
                orderDetails: orderDetails
            })
        });
    }

}


export default new OrdersService();